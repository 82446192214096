@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

body {
  background: url(https://www.laboontech.com/assets/img/hero-bg.png) fixed center;
  font-family: 'Open Sans', sans-serif;
}

.header {
  transition: all 1s;
}

.header.header-scrolled {
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background: #fff;
}

.sologan {
  font-size: 32px;
  line-height: 1.2;
  font-family: 'Nunito', sans-serif;
}

.sub-sologan {
  font-family: 'Nunito', sans-serif;
}

.shadow-blue {
  box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}

.title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

.sub-title {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
  color: #4154f1;
  text-transform: uppercase
}

.feature-icon {
  font-size: 44px;
}

.card-item .title {
  font-size: 24px;
  color: #012970;
  font-weight: 700;
  margin-bottom: 18px;
}

.counts .count-box {
  align-items: center;
  padding: 30px;
  width: 100%;
  background: #fff;
}

.counts .count-box i {
  font-size: 42px;
  line-height: 0;
  margin-right: 20px;
}

.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #0b198f;
}

.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
}

.services .item {
  padding: 24px 20px;
}

.footer {
  background: white url('./img/footer-bg.png') right top no-repeat;
  .title {
    font-size: 16px;
    font-weight: bold;
    color: #012970;
    text-transform: uppercase;
    padding-bottom: 12px;
    text-align: left;
  }
  ul {
    font-size: 15px;
    li {
      padding: 5px 0;
    }
  }
}

.shadow {
  box-shadow: 0px 0 30px #01296f14;
}

@media screen and (min-width: 768px) {
  .sologan {
    font-size: 48px;
  }
  
  .title {
    font-size: 38px;
  }
}